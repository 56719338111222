import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider, midnightTheme } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet, arbitrum, bsc, optimism, polygon, goerli, sepolia } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { ChakraProvider } from '@chakra-ui/react';
import { Analytics } from "@vercel/analytics/react"

const base = {
  id: 8_453,
  name: 'Base',
  network: 'base',
  iconUrl: 'https://images.mirror-media.xyz/publication-images/Sr07RSEOaY77dUnMbbkiZ.png?height=1200&width=1200',
  nativeCurrency: {
    decimals: 18,
    name: 'Eth',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://mainnet.base.org/'] },
    default: { http: ['https://mainnet.base.org/'] },
  },
  blockExplorers: {
    etherscan: { name: 'basescan', url: 'https://basescan.org/' },
    default: { name: 'basescan', url: 'https://basescan.org/' },
  },
}

const { chains, provider } = configureChains(
  [mainnet, arbitrum, base, bsc, optimism, polygon, sepolia, goerli],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'My RainbowKit App',
  projectId: '922eb14d995550ff5c7e0613a093f0d7',
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          chains={chains}
          coolMode
          theme={midnightTheme({
            fontStack: 'system',
            overlayBlur: 'large',
            borderRadius: 'large',
          })}
        >
          <App />
          <Analytics mode={'production'} />
        </RainbowKitProvider>
      </WagmiConfig>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
