import React from 'react';

const EmbeddedPopularCollections = () => {
  const handleClick = (e) => {
    // Prevent the default action
    e.preventDefault();
    // Open the link in a new tab/window
    window.open('https://explore.pxswap.xyz/', '_blank');
  };

  return (
    <div style={{ width: '100%', height: '700px', position: 'relative' }}>
      <iframe
        src="https://explore.pxswap.xyz/?embed=true"
        title="Popular Collections"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
      <div
        onClick={handleClick}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          zIndex: 1,
          opacity: 0,
        }}
      />
    </div>
  );
};

export default EmbeddedPopularCollections;
