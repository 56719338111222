export const socialData = {
  EMAIL: 'alikonuk1@protonmail.com',
  TWITTER: 'https://twitter.com/pxswap_xyz',
  ARBLET: 'https://arblet.xyz',
  GITHUB: 'https://github.com/alikonuk1',
  PXSWAP_GITHUB: 'https://github.com/pxswap-xyz',
  PXSWAP_DISCORD: 'https://discord.gg/qNtD477J4N',
  PXSWAP_CONTRACT_ADDRESS: 'https://goerli.etherscan.io/address/0x0BD983fa39243fF19957C9AfE35c01575E93e2bD#code',
  FLATICON: 'https://www.flaticon.com/free-icons/ethereum',
};
