import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { ethers } from 'ethers';
import {
  Button,
  Flex,
  VStack,
  Heading,
  Divider,
  Box,
  Grid,
  Image,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  InputGroup,
  Switch,
  FormControl,
  FormLabel,
  Tooltip,
  useToast,
  Spinner,
  Checkbox,
} from '@chakra-ui/react';
import { FaCheck, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { getAccount, getNetwork } from '@wagmi/core';
import { erc721ABI } from 'wagmi';
import {
  CONTRACT_GOERLI,
  CONTRACT_SEPOLIA,
  CONTRACT_ARBITRUM,
  CONTRACT_BINANCE,
  CONTRACT_POLYGON,
  CONTRACT_ETHEREUM,
  CONTRACT_OPTIMISM,
  CONTRACT_BASE,
  ABI,
} from '../data/abi.js';

const Inventory = () => {
  const [nfts, setNfts] = useState([]);
  const [selectedNFTs, setSelectedNFTs] = useState([]);
  const [selectedNFTAddresses, setSelectedNFTAddresses] = useState([]);
  const [selectedNFTIds, setSelectedNFTIds] = useState([]);
  const [selectedNFTs2, setSelectedNFTs2] = useState([]);
  const [selectedNFTAddresses2, setSelectedNFTAddresses2] = useState([]);
  const [selectedNFTIds2, setSelectedNFTIds2] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isApproved, setIsApproved] = useState({});
  const [counterPartyAddress, setCounterPartyAddress] = useState('0x0000000000000000000000000000000000000000');
  const [isPrivateOffer, setIsPrivateOffer] = useState(true);
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [approvalLoading, setApprovalLoading] = useState({});
  const [userNFTs, setUserNFTs] = useState({});
  const [selectedUserNFTs, setSelectedUserNFTs] = useState({});

  const accountAddress = getAccount().address;
  const [scrollBehavior, setScrollBehavior] = useState('inside');

  const { chain, chains } = getNetwork();
  let blockchain = '';
  let api = '';
  let api2 = '';
  let contract = '';

  if (chain) {
    if (chain.name === 'Ethereum') {
      blockchain = 'eth';
      api = 'https://api.reservoir.tools/collections/v6';
      api2 = `https://api.reservoir.tools/users/${accountAddress}/tokens/v9`;
      contract = CONTRACT_ETHEREUM;
    } else if (chain.name === 'Arbitrum One') {
      blockchain = 'arbitrum';
      api = 'https://api-arbitrum.reservoir.tools/collections/v6';
      api2 = `https://api-arbitrum.reservoir.tools/users/${accountAddress}/tokens/v9`;
      contract = CONTRACT_ARBITRUM;
    } else if (chain.name === 'Base') {
      blockchain = 'base';
      api = 'https://api-base.reservoir.tools/collections/v6';
      api2 = `https://api-base.reservoir.tools/users/${accountAddress}/tokens/v9`;
      contract = CONTRACT_BASE;
    } else if (chain.name === 'BNB Smart Chain') {
      blockchain = 'bsc';
      api = 'https://api-bsc.reservoir.tools/collections/v6';
      api2 = `https://api-bsc.reservoir.tools/users/${accountAddress}/tokens/v9`;
      contract = CONTRACT_BINANCE;
    } else if (chain.name === 'Optimism') {
      blockchain = 'optimism';
      api = 'https://api-optimism.reservoir.tools/collections/v6';
      api2 = `https://api-optimism.reservoir.tools/users/${accountAddress}/tokens/v9`;
      contract = CONTRACT_OPTIMISM;
    } else if (chain.name === 'Polygon') {
      blockchain = 'polygon';
      api = 'https://api-polygon.reservoir.tools/collections/v6';
      api2 = `https://api-polygon.reservoir.tools/users/${accountAddress}/tokens/v9`;
      contract = CONTRACT_POLYGON;
    } else if (chain.name === 'Goerli') {
      blockchain = 'eth_goerli';
      api = 'https://api-goerli.reservoir.tools/collections/v6';
      api2 = `https://api-goerli.reservoir.tools/users/${accountAddress}/tokens/v9`;
      contract = CONTRACT_GOERLI;
    } else if (chain.name === 'Sepolia') {
      blockchain = 'eth_sepolia';
      api = 'https://api-sepolia.reservoir.tools/collections/v6';
      api2 = `https://api-sepolia.reservoir.tools/users/${accountAddress}/tokens/v9`;
      contract = CONTRACT_SEPOLIA;
    }
  }

  const checkApprovalStatus = async (nftsData) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const approvalStatuses = {};

    for (const nft of nftsData) {
      const nftContract = new ethers.Contract(nft.contractAddress, erc721ABI, signer);
      const approvedAddress = await nftContract.getApproved(nft.tokenId);
      if (approvedAddress.toLowerCase() === contract.toLowerCase()) {
        approvalStatuses[`${nft.contractAddress}-${nft.tokenId}`] = true;
      } else {
        approvalStatuses[`${nft.contractAddress}-${nft.tokenId}`] = false;
      }
    }

    setIsApproved(approvalStatuses);
  };

  useEffect(() => {
    const fetchData = async () => {
      const url = api2;
      try {
        const response = await axios.get(url, {
          headers: {
            accept: '*/*',
            'x-api-key': '1f97bee7-5e00-5d41-b928-583f686a13d0', // Use the correct API key
          },
        });
        const nftsData = response.data.tokens.map((item) => {
          const token = item.token; // Access the nested token object
          const contractAddress = token.contract || 'Unknown contract';
          const tokenId = token.tokenId || 'Unknown tokenId';
          // Choose an appropriate image property based on availability
          const imageUrl = token.image || token.imageSmall || token.imageLarge || 'Default image URL';
          const name = token.name || ' ';
          const collectionName = token.collection.name; // It seems collection name is directly under token.collection.name

          return { contractAddress, tokenId, imageUrl, name, collectionName };
        });
        setNfts(nftsData);
        checkApprovalStatus(nftsData); // Check approval status after setting NFTs
      } catch (error) {
        console.error('Failed to fetch NFTs:', error);
      }
    };

    fetchData();
  }, [accountAddress, api2]); // Depend on accountAddress and api2 to refetch if any changes

  const fetchOffers = useCallback(async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contractInstance = new ethers.Contract(contract, ABI, provider);
      const activeTrades = await contractInstance.getActiveTrades();

      const offersData = await Promise.all(
        activeTrades.map(async (tradeHash) => {
          const [initiator, offerNfts, offerNftIds, requestNfts, counterParty] = await contractInstance.getOffer(
            tradeHash
          );

          // Process offers where the connected wallet is either the initiator or the counterparty
          const isInitiator = initiator.toLowerCase() === accountAddress.toLowerCase();
          const isCounterParty = counterParty.toLowerCase() === accountAddress.toLowerCase();

          if (!isInitiator && !isCounterParty) {
            return null;
          }

          // Fetch metadata for offered NFTs
          const offeredNftMetadata = await Promise.all(
            offerNfts.map(async (nftAddress, index) => {
              const tokenId = offerNftIds[index].toString(); // Convert BigNumber to string
              let url;
              if (chain.name === 'Polygon') {
                url = `https://api-polygon.reservoir.tools/tokens/v7?collection=${nftAddress}`;
              } else if (chain.name === 'Goerli') {
                url = `https://api-goerli.reservoir.tools/tokens/v7?collection=${nftAddress}`;
              } else if (chain.name === 'Sepolia') {
                url = `https://api-sepolia.reservoir.tools/tokens/v7?collection=${nftAddress}`;
              } else if (chain.name === 'Arbitrum One') {
                url = `https://api-arbitrum.reservoir.tools/tokens/v7?collection=${nftAddress}`;
              } else if (chain.name === 'BNB Smart Chain') {
                url = `https://api-bsc.reservoir.tools/tokens/v7?collection=${nftAddress}`;
              } else if (chain.name === 'Optimism') {
                url = `https://api-optimism.reservoir.tools/tokens/v7?collection=${nftAddress}`;
              } else if (chain.name === 'Ethereum') {
                url = `https://api.reservoir.tools/tokens/v7?collection=${nftAddress}`;
              } else if (chain.name === 'Base') {
                url = `https://api-base.reservoir.tools/tokens/v7?collection=${nftAddress}`;
              }
              const response = await axios.get(url, {
                headers: {
                  accept: '*/*',
                  'x-api-key': '1f97bee7-5e00-5d41-b928-583f686a13d0', // Use the correct API key
                },
              });
              return {
                contractAddress: nftAddress,
                tokenId,
                imageUrl: response.data.tokens[0].token.collection.image, // Adjust based on actual response structure
                name: response.data.tokens[0].token.collection.name, // Adjust based on actual response structure
              };
            })
          );

          // Fetch metadata for requested NFTs
          const requestedNftMetadata = await Promise.all(
            requestNfts.map(async (nftAddress) => {
              let url;
              if (chain.name === 'Polygon') {
                url = `https://api-polygon.reservoir.tools/tokens/v7?collection=${nftAddress}`;
              } else if (chain.name === 'Goerli') {
                url = `https://api-goerli.reservoir.tools/tokens/v7?collection=${nftAddress}`;
              } else if (chain.name === 'Sepolia') {
                url = `https://api-sepolia.reservoir.tools/tokens/v7?collection=${nftAddress}`;
              } else if (chain.name === 'Arbitrum One') {
                url = `https://api-arbitrum.reservoir.tools/tokens/v7?collection=${nftAddress}`;
              } else if (chain.name === 'BNB Smart Chain') {
                url = `https://api-bsc.reservoir.tools/tokens/v7?collection=${nftAddress}`;
              } else if (chain.name === 'Optimism') {
                url = `https://api-optimism.reservoir.tools/tokens/v7?collection=${nftAddress}`;
              } else if (chain.name === 'Ethereum') {
                url = `https://api.reservoir.tools/tokens/v7?collection=${nftAddress}`;
              } else if (chain.name === 'Base') {
                url = `https://api-base.reservoir.tools/tokens/v7?collection=${nftAddress}`;
              }
              const response = await axios.get(url, {
                headers: {
                  accept: '*/*',
                  'x-api-key': '1f97bee7-5e00-5d41-b928-583f686a13d0', // Use the correct API key
                },
              });
              return {
                contractAddress: nftAddress,
                imageUrl: response.data.tokens[0].token.collection.image, // Adjust based on actual response structure
                name: response.data.tokens[0].token.collection.name, // Adjust based on actual response structure
              };
            })
          );

          return {
            tradeHash,
            initiator,
            offerNfts: offeredNftMetadata,
            offerNftIds: offerNftIds.map((id) => id.toString()), // Convert BigNumber to string
            requestNfts: requestedNftMetadata,
            counterParty,
            isInitiator,
            isCounterParty,
          };
        })
      );

      // Filter out null values
      const filteredOffersData = offersData.filter((offer) => offer !== null);
      setOffers(filteredOffersData);
    } catch (error) {
      console.error('Failed to fetch offers:', error);
    }
  }, [contract, accountAddress]);

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  const isSelected = (contractAddress, tokenId) => {
    return selectedNFTs.some((nft) => nft.contractAddress === contractAddress && nft.tokenId === tokenId);
  };

  const toggleNFTSelection = (nft) => {
    if (isSelected(nft.contractAddress, nft.tokenId)) {
      setSelectedNFTs(
        selectedNFTs.filter((item) => !(item.contractAddress === nft.contractAddress && item.tokenId === nft.tokenId))
      );
      setSelectedNFTAddresses(selectedNFTAddresses.filter((address) => address !== nft.contractAddress));
      setSelectedNFTIds(selectedNFTIds.filter((id) => id !== nft.tokenId));
    } else {
      setSelectedNFTs([...selectedNFTs, nft]);
      setSelectedNFTAddresses([...selectedNFTAddresses, nft.contractAddress]);
      setSelectedNFTIds([...selectedNFTIds, nft.tokenId]);
    }
  };

  const isSelected2 = (contractAddress) => {
    return selectedNFTAddresses2.some((nft) => nft.id === contractAddress);
  };

  const toggleNFTSelection2 = (nft) => {
    if (!nft.id) {
      console.error('NFT object is missing required properties:', nft);
      return;
    }

    if (isSelected2(nft.id)) {
      setSelectedNFTs2(selectedNFTs2.filter((item) => !(item.id === nft.id)));
      setSelectedNFTAddresses2(selectedNFTAddresses2.filter((address) => address !== nft.id));
    } else if (!selectedNFTs2.some((item) => item.id === nft.id)) {
      setSelectedNFTs2([...selectedNFTs2, nft]);
      setSelectedNFTAddresses2([...selectedNFTAddresses2, nft.id]);
    }
  };

  const deselectNFTSelection2 = (nft) => {
    setSelectedNFTs2((prevSelectedNFTs2) => {
      const updated = prevSelectedNFTs2.filter((item) => !(item.id === nft.id));
      return updated;
    });

    setSelectedNFTAddresses2((prevSelectedNFTAddresses2) => {
      const updated = prevSelectedNFTAddresses2.filter((address) => address !== nft.id);
      return updated;
    });

    setSelectedNFTIds2((prevSelectedNFTIds2) => {
      const updated = prevSelectedNFTIds2.filter((id) => id !== nft.id);
      return updated;
    });
  };

  const handleProceedClick = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeOfferModal = () => {
    setIsOfferModalOpen(false);
    setSelectedOffer(null);
  };

  const handleSearch = useCallback(
    _.debounce(async (value) => {
      if (value) {
        try {
          let response;
          if (value.startsWith('0x')) {
            // Check if the value is a contract address
            response = await axios.get(api, {
              headers: {
                'x-api-key': '1f97bee7-5e00-5d41-b928-583f686a13d0',
              },
              params: {
                id: value, // Use the correct parameter name for the API
              },
            });
          } else {
            // If it's not an address, search by name
            response = await axios.get(api, {
              headers: {
                'x-api-key': '1f97bee7-5e00-5d41-b928-583f686a13d0',
              },
              params: {
                name: value,
              },
            });
          }
          setSearchResults(response.data.collections);
        } catch (err) {
          console.error(err);
        }
      } else {
        setSearchResults([]);
      }
    }, 500),
    []
  );

  const handleTransactionAction = async () => {
    try {
      // Validate the arguments
      if (selectedNFTAddresses.length === 0 || selectedNFTIds.length === 0 || selectedNFTAddresses2.length === 0) {
        throw new Error('Missing required arguments for the transaction.');
      }

      if (
        selectedNFTAddresses.includes(undefined) ||
        selectedNFTIds.includes(undefined) ||
        selectedNFTAddresses2.includes(undefined)
      ) {
        throw new Error('Undefined values detected in the NFT selection.');
      }

      console.log('Selected NFT Addresses:', selectedNFTAddresses);
      console.log('Selected NFT IDs:', selectedNFTIds);
      console.log('Selected Requested NFT Addresses:', selectedNFTAddresses2);
      console.log('Counter Party Address:', counterPartyAddress);

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contractInstance = new ethers.Contract(contract, ABI, signer);

      const tx = await contractInstance.openTrade(
        selectedNFTAddresses,
        selectedNFTIds,
        selectedNFTAddresses2,
        counterPartyAddress
      );
      await tx.wait();

      console.log('Transaction successful:', tx);
    } catch (error) {
      console.error('Transaction failed:', error);
    }
  };

  const handleIndividualApprove = async (nftAddress, tokenId) => {
    try {
      setApprovalLoading((prev) => ({ ...prev, [`${nftAddress}-${tokenId}`]: true }));
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const nftContract = new ethers.Contract(nftAddress, erc721ABI, signer);

      const tx = await nftContract.approve(contract, tokenId);
      await tx.wait();

      console.log('Approval successful:', tx);
      setIsApproved((prev) => ({ ...prev, [`${nftAddress}-${tokenId}`]: true }));

      setSelectedUserNFTs((prev) => ({
        ...prev,
        [nftAddress]: [{ contractAddress: nftAddress, tokenId }],
      }));
    } catch (error) {
      console.error('Approval failed:', error);
    } finally {
      setApprovalLoading((prev) => ({ ...prev, [`${nftAddress}-${tokenId}`]: false }));
    }
  };

  const isOfferAcceptable = () => {
    if (Object.keys(selectedUserNFTs).length !== selectedOffer.requestNfts.length) {
      console.log('Not all requested NFTs are selected');
      return false;
    }

    for (const [contractAddress, nfts] of Object.entries(selectedUserNFTs)) {
      if (nfts.length === 0) {
        console.log(`No NFT selected for collection: ${contractAddress}`);
        return false;
      }
      for (const nft of nfts) {
        const key = `${contractAddress}-${nft.tokenId}`;
        if (!isApproved[key]) {
          console.log(`NFT not approved: ${key}`);
          return false;
        }
      }
    }

    console.log('Offer is acceptable');
    return true;
  };

  const handleAcceptTrade = async (tradeHash) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contractInstance = new ethers.Contract(contract, ABI, signer);

      const selectedNFTs = Object.values(selectedUserNFTs).flat();
      const tokenIds = selectedNFTs.map((nft) => nft.tokenId);

      const tx = await contractInstance.acceptTrade(tradeHash, tokenIds);
      await tx.wait();

      console.log('Accept trade successful:', tx);
      setIsOfferModalOpen(false);
      fetchOffers();
    } catch (error) {
      console.error('Accept trade failed:', error);
    }
  };

  const handleOfferClick = (offer) => {
    setSelectedOffer(offer);
    setIsOfferModalOpen(true);
  };

  const handleCancelTrade = async (tradeHash) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contractInstance = new ethers.Contract(contract, ABI, signer);

      const tx = await contractInstance.cancelTrade(tradeHash);
      await tx.wait();

      console.log('Cancel trade successful:', tx);
      setIsOfferModalOpen(false);
      fetchOffers(); // Refresh the offers list
    } catch (error) {
      console.error('Cancel trade failed:', error);
    }
  };

  useEffect(() => {
    const fetchUserNFTs = async () => {
      if (selectedOffer && !selectedOffer.isInitiator) {
        const userNFTsData = {};
        for (const nft of selectedOffer.requestNfts) {
          const normalizedAddress = ethers.utils.getAddress(nft.contractAddress);
          try {
            let url;
            if (chain.name === 'Polygon') {
              url = `https://api-polygon.reservoir.tools/users/${accountAddress}/tokens/v7?collection=${normalizedAddress}`;
            } else if (chain.name === 'Goerli') {
              url = `https://api-goerli.reservoir.tools/users/${accountAddress}/tokens/v7?collection=${normalizedAddress}`;
            } else if (chain.name === 'Sepolia') {
              url = `https://api-sepolia.reservoir.tools/users/${accountAddress}/tokens/v7?collection=${normalizedAddress}`;
            } else if (chain.name === 'Arbitrum One') {
              url = `https://api-arbitrum.reservoir.tools/users/${accountAddress}/tokens/v7?collection=${normalizedAddress}`;
            } else if (chain.name === 'BNB Smart Chain') {
              url = `https://api-bsc.reservoir.tools/users/${accountAddress}/tokens/v7?collection=${normalizedAddress}`;
            } else if (chain.name === 'Optimism') {
              url = `https://api-optimism.reservoir.tools/users/${accountAddress}/tokens/v7?collection=${normalizedAddress}`;
            } else if (chain.name === 'Ethereum') {
              url = `https://api.reservoir.tools/users/${accountAddress}/tokens/v7?collection=${normalizedAddress}`;
            } else if (chain.name === 'Base') {
              url = `https://api-base.reservoir.tools/users/${accountAddress}/tokens/v7?collection=${normalizedAddress}`;
            }
            const response = await axios.get(url, {
              headers: {
                accept: '*/*',
                'x-api-key': '1f97bee7-5e00-5d41-b928-583f686a13d0',
              },
            });
            userNFTsData[normalizedAddress] = response.data.tokens.map((token) => ({
              tokenId: token.token.tokenId,
              name: token.token.collection.name,
              image: token.token.collection.image,
            }));
          } catch (error) {
            console.error(`Failed to fetch NFTs for collection ${normalizedAddress}:`, error);
            userNFTsData[normalizedAddress] = [];
          }
        }
        setUserNFTs(userNFTsData);
      }
    };

    fetchUserNFTs();
  }, [selectedOffer, accountAddress]);

  const toast = useToast();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast({
          title: 'Address copied',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      },
      (err) => {
        console.error('Failed to copy: ', err);
        toast({
          title: 'Failed to copy address',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    );
  };

  const AddressDisplay = ({ address }) => (
    <Tooltip label={address}>
      <Text
        fontSize="xs"
        color="gray.500"
        cursor="pointer"
        onClick={(e) => {
          e.stopPropagation();
          copyToClipboard(address);
        }}
        _hover={{ textDecoration: 'underline' }}
      >
        {`${address.substr(0, 6)}...${address.substr(-4)}`}
      </Text>
    </Tooltip>
  );

  return (
    <VStack spacing={6} borderRadius="lg" boxShadow="lg" w="100%">
      <Heading>Inventory</Heading>
      <Tabs isLazy colorScheme="gray" width="60%">
        <TabList>
          <Tab fontWeight="bold">Items</Tab>
          <Tab fontWeight="bold">Offers</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box py={10} display="flex" flexDirection="column" alignItems="center">
              <Grid
                templateColumns={[
                  'repeat(1, 1fr)',
                  'repeat(2, 1fr)',
                  'repeat(3, 1fr)',
                  'repeat(4, 1fr)',
                  'repeat(6, 1fr)',
                ]}
                gap={3}
                mt={3}
              >
                {nfts.map((nft) => (
                  <Box
                    key={`${nft.contractAddress}/${nft.tokenId}`}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="xl"
                    borderWidth="3px"
                    borderColor={isSelected(nft.contractAddress, nft.tokenId) ? 'purple.500' : 'inherit'}
                    p={3}
                    onClick={() => toggleNFTSelection(nft)}
                    width="180px"
                    height="240px"
                    overflow="hidden"
                  >
                    <Image boxSize="120px" borderRadius="md" shadow="md" src={nft.imageUrl} alt={nft.name} />
                    <Text noOfLines={1} fontWeight="bold" mt={4} isTruncated>
                      {nft.name}
                    </Text>
                    <Text isTruncated noOfLines={1}>
                      {nft.collectionName}
                    </Text>
                    <AddressDisplay address={nft.contractAddress} />
                  </Box>
                ))}
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel>
            <Box py={10} display="flex" flexDirection="column" alignItems="center">
              <Grid
                templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
                gap={3}
                mt={3}
                px={[1, 3, 6]}
              >
                {offers.length > 0 ? (
                  offers.map((offer) => (
                    <Box
                      key={offer.tradeHash}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="xl"
                      borderWidth="3px"
                      borderColor="inherit"
                      p={3}
                      width="240px"
                      height="300px"
                      overflow="hidden"
                      onClick={() => handleOfferClick(offer)}
                      cursor="pointer"
                      position="relative"
                    >
                      <Flex justifyContent="space-between" width="100%">
                        <Box>
                          <Text fontWeight="bold" mb={1}>
                            {offer.isInitiator ? 'Offered:' : 'Requested:'}
                          </Text>
                          {(offer.isInitiator ? offer.offerNfts : offer.requestNfts).map((nft) => (
                            <Box
                              key={`${nft.contractAddress}-${nft.tokenId}`}
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              <Image boxSize="60px" borderRadius="md" shadow="md" src={nft.imageUrl} alt={nft.name} />
                              <Text noOfLines={1} isTruncated>
                                {nft.name}
                              </Text>
                              <AddressDisplay address={nft.contractAddress} />
                            </Box>
                          ))}
                        </Box>
                        <Box>
                          <Text fontWeight="bold" mb={1}>
                            {offer.isInitiator ? 'Requested:' : 'Offered:'}
                          </Text>
                          {(offer.isInitiator ? offer.requestNfts : offer.offerNfts).map((nft) => (
                            <Box key={nft.contractAddress} display="flex" flexDirection="column" alignItems="center">
                              <Image boxSize="60px" borderRadius="md" shadow="md" src={nft.imageUrl} alt={nft.name} />
                              <Text noOfLines={1} isTruncated>
                                {nft.name}
                              </Text>
                              <AddressDisplay address={nft.contractAddress} />
                            </Box>
                          ))}
                        </Box>
                      </Flex>
                      <Box
                        position="absolute"
                        top="2"
                        right="2"
                        bg={offer.isInitiator ? 'green.500' : 'blue.500'}
                        borderRadius="full"
                        p="1"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {offer.isInitiator ? (
                          <FaArrowUp size="12" color="white" />
                        ) : (
                          <FaArrowDown size="12" color="white" />
                        )}
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Text>No offers to show.</Text>
                )}
              </Grid>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Divider />
      {selectedNFTs.length > 0 && (
        <Flex
          position="sticky"
          bottom="0"
          p={3}
          justifyContent="right"
          width="100%"
          borderTop="1px solid"
          borderColor="gray.900"
          backgroundColor="rgba(18, 18, 18, 0.6)"
          backdropFilter="blur(4px)"
        >
          <Button
            onClick={handleProceedClick}
            bgColor="black"
            borderRadius={'xl'}
            borderColor="gray.500"
            borderWidth="1px"
            color="white"
            size="md"
          >
            Proceed with Selected NFTs
          </Button>
        </Flex>
      )}
      <Modal isOpen={isOpen} onClose={closeModal} size="xl" isCentered scrollBehavior={scrollBehavior}>
        <ModalOverlay bg="blackAlpha.100" backdropFilter="blur(9px)" />
        <ModalContent bg="blackAlpha.900" borderRadius={'xl'}>
          <Flex w="100%" justifyContent="space-between">
            <Flex flex={1} justifyContent="center" alignItems="center">
              <ModalHeader>Offered</ModalHeader>
            </Flex>
            <Flex flex={1} justifyContent="center" alignItems="center">
              <ModalHeader>Requested</ModalHeader>
            </Flex>
          </Flex>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <VStack spacing={3} alignItems="center">
                <Divider />
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  {selectedNFTs.map((nft) => (
                    <Box key={nft.tokenId} display="flex" flexDirection="column" alignItems="center">
                      <Image boxSize="90px" borderRadius="lg" shadow="md" src={nft.imageUrl} alt={nft.name} />
                      <Text noOfLines={1} isTruncated mt={2}>
                        {nft.name}
                      </Text>
                      <AddressDisplay address={nft.contractAddress} />
                      <Button
                        mt={2}
                        bgColor="black"
                        borderRadius={'xl'}
                        borderColor="gray.500"
                        borderWidth="1px"
                        color="white"
                        size="sm"
                        onClick={() => handleIndividualApprove(nft.contractAddress, nft.tokenId)}
                        disabled={
                          isApproved[`${nft.contractAddress}-${nft.tokenId}`] ||
                          approvalLoading[`${nft.contractAddress}-${nft.tokenId}`]
                        }
                      >
                        {isApproved[`${nft.contractAddress}-${nft.tokenId}`] ? (
                          <FaCheck />
                        ) : approvalLoading[`${nft.contractAddress}-${nft.tokenId}`] ? (
                          <Spinner size="sm" />
                        ) : (
                          'Approve'
                        )}
                      </Button>
                    </Box>
                  ))}
                </Grid>
              </VStack>
              <VStack spacing={3} alignItems="flex-start">
                <Divider />
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  {selectedNFTs2.map((nft) => (
                    <Box key={nft.id} onClick={() => deselectNFTSelection2(nft)}>
                      <Image boxSize="90px" borderRadius="lg" shadow="md" src={nft.image} alt={nft.name} />
                      <Text noOfLines={1} isTruncated mt={2}>
                        {nft.name}
                      </Text>
                      <Tooltip label={nft.id}>
                        <Text fontSize="xs" color="gray.500" isTruncated>
                          {`${nft.id.substr(0, 6)}...${nft.id.substr(-4)}`}
                        </Text>
                      </Tooltip>
                    </Box>
                  ))}
                </Grid>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Search by name or address"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      handleSearch(e.target.value);
                    }}
                  />
                </InputGroup>
                {searchResults.map((nft) => (
                  <Flex key={nft.id} onClick={() => toggleNFTSelection2(nft)}>
                    <Image boxSize="60px" borderRadius="md" shadow="md" src={nft.image} alt={nft.name} />
                    <Flex direction="column">
                      <Text fontWeight="bold" ml={2} w={'150px'}>
                        {nft.name}
                      </Text>
                      <Tooltip label={nft.id}>
                        <Text fontWeight="light" ml={2} w={'150px'} isTruncated>
                          {`${nft.id.substr(0, 6)}...${nft.id.substr(-4)}`}
                        </Text>
                      </Tooltip>
                    </Flex>
                  </Flex>
                ))}
              </VStack>
            </Grid>
          </ModalBody>
          <FormControl display="flex" alignItems="center" p={3}>
            <FormLabel htmlFor="private-offer" mb="0">
              Private offer
            </FormLabel>
            <Switch
              id="private-offer"
              isChecked={!isPrivateOffer}
              onChange={() => setIsPrivateOffer(!isPrivateOffer)}
            />
          </FormControl>
          {!isPrivateOffer && (
            <Input
              type="text"
              flexShrink={0}
              placeholder="Counterparty Address"
              value={counterPartyAddress}
              onChange={(e) => setCounterPartyAddress(e.target.value)}
              mb={3}
            />
          )}
          <Button
            bgColor="black"
            borderRadius={'xl'}
            borderColor="gray.500"
            borderWidth="1px"
            color="white"
            size="md"
            flexShrink={0}
            onClick={handleTransactionAction}
          >
            Offer
          </Button>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOfferModalOpen} onClose={closeOfferModal} size="xl" isCentered scrollBehavior={scrollBehavior}>
        <ModalOverlay bg="blackAlpha.100" backdropFilter="blur(9px)" />
        <ModalContent bg="blackAlpha.900" borderRadius={'xl'}>
          <ModalHeader>Offer Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {selectedOffer && (
              <VStack spacing={3} alignItems="center">
                <Divider />
                {selectedOffer.counterParty !== '0x0000000000000000000000000000000000000000' && (
                  <>
                    <Text fontWeight="bold">Counterparty:</Text>
                    <AddressDisplay address={selectedOffer.counterParty} />
                  </>
                )}
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <Box>
                    <Text fontWeight="bold">Offer NFTs:</Text>
                    <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={3} mt={3}>
                      {selectedOffer.offerNfts.map((nft) => (
                        <Box
                          key={`${nft.contractAddress}-${nft.tokenId}`}
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="xl"
                          borderWidth="3px"
                          borderColor="inherit"
                          p={3}
                          width="180px"
                          height="240px"
                          overflow="hidden"
                        >
                          <Image boxSize="120px" borderRadius="md" shadow="md" src={nft.imageUrl} alt={nft.name} />
                          <Text noOfLines={1} isTruncated mt={2}>
                            {nft.name}
                          </Text>
                          <AddressDisplay address={nft.contractAddress} />
                        </Box>
                      ))}
                    </Grid>
                  </Box>
                  <Box>
                    <Text fontWeight="bold">Request NFTs:</Text>
                    <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={3} mt={3}>
                      {selectedOffer.requestNfts.map((nft) => (
                        <Box
                          key={nft.contractAddress}
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="xl"
                          borderWidth="3px"
                          borderColor="inherit"
                          p={3}
                          width="180px"
                          height="240px"
                          overflow="hidden"
                        >
                          <Image boxSize="120px" borderRadius="md" shadow="md" src={nft.imageUrl} alt={nft.name} />
                          <Text noOfLines={1} isTruncated mt={2}>
                            {nft.name}
                          </Text>
                          <AddressDisplay address={nft.contractAddress} />
                        </Box>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
                {selectedOffer.isInitiator ? (
                  <Button
                    bgColor="red.600"
                    borderRadius={'xl'}
                    borderColor="gray.500"
                    borderWidth="1px"
                    color="white"
                    size="md"
                    onClick={() => handleCancelTrade(selectedOffer.tradeHash)}
                  >
                    Cancel Offer
                  </Button>
                ) : (
                  <>
                    {selectedOffer.requestNfts.every((nft) => {
                      const normalizedAddress = ethers.utils.getAddress(nft.contractAddress);
                      return userNFTs[normalizedAddress] && userNFTs[normalizedAddress].length > 0;
                    }) ? (
                      <>
                        <Text fontWeight="bold" mt={4}>
                          Select your NFTs to trade:
                        </Text>
                        {selectedOffer.requestNfts.map((nft) => {
                          const normalizedAddress = ethers.utils.getAddress(nft.contractAddress);
                          const userOwnedNFTs = userNFTs[normalizedAddress] || [];
                          return (
                            <Box key={normalizedAddress} width="100%">
                              <Text fontWeight="bold" mb={2}>
                                {nft.name}
                              </Text>
                              {userOwnedNFTs.length > 0 ? (
                                <Grid templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)']} gap={3}>
                                  {userOwnedNFTs.map((userNFT) => (
                                    <Box
                                      key={userNFT.tokenId}
                                      borderWidth="1px"
                                      borderRadius="lg"
                                      p={2}
                                      cursor="pointer"
                                      onClick={() => {
                                        setSelectedUserNFTs((prev) => ({
                                          ...prev,
                                          [normalizedAddress]: [userNFT],
                                        }));
                                      }}
                                      bg={
                                        selectedUserNFTs[normalizedAddress]?.[0]?.tokenId === userNFT.tokenId
                                          ? 'blue.500'
                                          : 'transparent'
                                      }
                                    >
                                      <Image
                                        src={userNFT.image}
                                        alt={userNFT.name}
                                        boxSize="100px"
                                        objectFit="cover"
                                        borderRadius="md"
                                        mb={2}
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = 'https://via.placeholder.com/100?text=No+Image';
                                        }}
                                      />
                                      <Text fontSize="sm" isTruncated>
                                        {userNFT.name} (ID: {userNFT.tokenId})
                                      </Text>
                                      {selectedUserNFTs[normalizedAddress]?.[0]?.tokenId === userNFT.tokenId && (
                                        <Button
                                          mt={2}
                                          size="sm"
                                          width="100%"
                                          colorScheme={
                                            isApproved[`${normalizedAddress}-${userNFT.tokenId}`] ? 'green' : 'blue'
                                          }
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            if (!isApproved[`${normalizedAddress}-${userNFT.tokenId}`]) {
                                              handleIndividualApprove(normalizedAddress, userNFT.tokenId);
                                            }
                                          }}
                                          isLoading={approvalLoading[`${normalizedAddress}-${userNFT.tokenId}`]}
                                          loadingText="Approving"
                                        >
                                          {isApproved[`${normalizedAddress}-${userNFT.tokenId}`] ? (
                                            <FaCheck />
                                          ) : (
                                            'Approve'
                                          )}
                                        </Button>
                                      )}
                                    </Box>
                                  ))}
                                </Grid>
                              ) : (
                                <Text>You don't have any NFTs from this collection.</Text>
                              )}
                            </Box>
                          );
                        })}
                        <Button
                          mt={4}
                          bgColor="green.600"
                          borderRadius={'xl'}
                          borderColor="black.500"
                          borderWidth="1px"
                          color="white"
                          size="md"
                          onClick={() => handleAcceptTrade(selectedOffer.tradeHash)}
                          isDisabled={!isOfferAcceptable()}
                        >
                          Accept Offer
                        </Button>
                      </>
                    ) : (
                      <Button
                        bgColor="gray.600"
                        borderRadius={'xl'}
                        borderColor="black.500"
                        borderWidth="1px"
                        color="white"
                        size="md"
                        isDisabled={true}
                      >
                        You don't have the requested NFTs
                      </Button>
                    )}
                  </>
                )}
                <Divider />
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Inventory;
