import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { ethers } from 'ethers';
import {
  Box,
  Flex,
  Grid,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Heading,
  Divider,
  Button,
  Tooltip,
  useToast,
  Spinner, // Import Spinner component
} from '@chakra-ui/react';
import { getAccount, getNetwork } from '@wagmi/core';
import {
  CONTRACT_GOERLI,
  CONTRACT_SEPOLIA,
  CONTRACT_ARBITRUM,
  CONTRACT_BINANCE,
  CONTRACT_POLYGON,
  CONTRACT_ETHEREUM,
  CONTRACT_OPTIMISM,
  CONTRACT_BASE,
  ABI,
} from '../data/abi.js';
import { erc721ABI } from 'wagmi';
import useApproveNFT from './useApproveNFT';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { FaCheck } from 'react-icons/fa';

const Feed = () => {
  const [activeOffers, setActiveOffers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [contract, setContract] = useState(null);
  const [userNFTs, setUserNFTs] = useState({});
  const [selectedUserNFTs, setSelectedUserNFTs] = useState({});
  const [isApproved, setIsApproved] = useState({});
  const [approvalLoading, setApprovalLoading] = useState({});
  const [contractA, setContractA] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const accountAddress = getAccount().address;
  const { chain } = getNetwork();
  let blockchain = '';
  let api = '';
  let api2 = '';

  if (chain) {
    if (chain.name === 'Ethereum') {
      blockchain = 'eth';
      api = 'https://api.reservoir.tools/collections/v6';
      api2 = `https://api.reservoir.tools/users/${accountAddress}/tokens/v9`;
    } else if (chain.name === 'Arbitrum One') {
      blockchain = 'arbitrum';
      api = 'https://api-arbitrum.reservoir.tools/collections/v6';
      api2 = `https://api-arbitrum.reservoir.tools/users/${accountAddress}/tokens/v9`;
    } else if (chain.name === 'Base') {
      blockchain = 'base';
      api = 'https://api-base.reservoir.tools/collections/v6';
      api2 = `https://api-base.reservoir.tools/users/${accountAddress}/tokens/v9`;
    } else if (chain.name === 'BNB Smart Chain') {
      blockchain = 'bsc';
      api = 'https://api-bsc.reservoir.tools/collections/v6';
      api2 = `https://api-bsc.reservoir.tools/users/${accountAddress}/tokens/v9`;
    } else if (chain.name === 'Optimism') {
      blockchain = 'optimism';
      api = 'https://api-optimism.reservoir.tools/collections/v6';
      api2 = `https://api-optimism.reservoir.tools/users/${accountAddress}/tokens/v9`;
    } else if (chain.name === 'Polygon') {
      blockchain = 'polygon';
      api = 'https://api-polygon.reservoir.tools/collections/v6';
      api2 = `https://api-polygon.reservoir.tools/users/${accountAddress}/tokens/v9`;
    } else if (chain.name === 'Goerli') {
      blockchain = 'eth_goerli';
      api = 'https://api-goerli.reservoir.tools/collections/v6';
      api2 = `https://api-goerli.reservoir.tools/users/${accountAddress}/tokens/v9`;
    } else if (chain.name === 'Sepolia') {
      blockchain = 'eth_sepolia';
      api = 'https://api-sepolia.reservoir.tools/collections/v6';
      api2 = `https://api-sepolia.reservoir.tools/users/${accountAddress}/tokens/v9`;
    }
  }

  useEffect(() => {
    const loadOffers = async () => {
      if (contract) {
        setIsLoading(true); // Set loading to true when starting to fetch offers
        try {
          const activeTrades = await fetchActiveTrades();
          const offersPromises = activeTrades.map(async (tradeHash) => fetchOfferDetails(tradeHash));
          const offers = await Promise.all(offersPromises);
          setActiveOffers(offers.filter((offer) => offer !== null));
        } catch (error) {
          console.error('Error loading offers:', error);
        } finally {
          setIsLoading(false); // Set loading to false when done, regardless of success or failure
        }
      }
    };

    loadOffers();
  }, [contract]);

  useEffect(() => {
    console.log('Current chain:', chain);
    if (chain) {
      let newContractA;
      if (chain.name === 'Polygon') {
        newContractA = CONTRACT_POLYGON;
      } else if (chain.name === 'Goerli') {
        newContractA = CONTRACT_GOERLI;
      } else if (chain.name === 'Sepolia') {
        newContractA = CONTRACT_SEPOLIA;
      } else if (chain.name === 'Arbitrum One') {
        newContractA = CONTRACT_ARBITRUM;
      } else if (chain.name === 'BNB Smart Chain') {
        newContractA = CONTRACT_BINANCE;
      } else if (chain.name === 'Optimism') {
        newContractA = CONTRACT_OPTIMISM;
      } else if (chain.name === 'Ethereum') {
        newContractA = CONTRACT_ETHEREUM;
      } else if (chain.name === 'Base') {
        newContractA = CONTRACT_BASE;
      }

      if (newContractA !== contractA) {
        console.log('Setting contractA to:', newContractA);
        setContractA(newContractA);
      }
    }
  }, [chain, contractA]);

  useEffect(() => {
    const checkApprovalStatus = async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const approvalStatuses = {};

      for (const [contractAddress, nfts] of Object.entries(selectedUserNFTs)) {
        for (const nft of nfts) {
          const nftContract = new ethers.Contract(contractAddress, erc721ABI, signer);
          const approvedAddress = await nftContract.getApproved(nft.tokenId);
          approvalStatuses[`${contractAddress}-${nft.tokenId}`] =
            approvedAddress.toLowerCase() === contractA.toLowerCase();
        }
      }

      setIsApproved(approvalStatuses);
    };

    if (Object.keys(selectedUserNFTs).length > 0) {
      checkApprovalStatus();
    }
  }, [selectedUserNFTs, contractA]);

  useEffect(() => {
    if (contractA) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contractInstance = new ethers.Contract(contractA, ABI, signer);
      setContract(contractInstance);
    }
  }, [contractA]);

  const fetchActiveTrades = async () => {
    if (!contract) {
      console.error('Contract is not initialized.');
      return [];
    }
    try {
      const activeTradesHashes = await contract.getActiveTrades();
      return activeTradesHashes;
    } catch (error) {
      console.error('Error fetching active trades:', error);
      return [];
    }
  };

  const fetchOfferDetails = async (tradeHash) => {
    if (!contract) {
      console.error('Contract is not initialized.');
      return null;
    }
    try {
      const [initiator, offeredNfts, offeredNftIds, requestNfts, counterParty] = await contract.getOffer(tradeHash);
      if (counterParty !== '0x0000000000000000000000000000000000000000') {
        return null; // Skip this offer
      }
      const offeredNftDetails = await Promise.all(
        offeredNfts.map(async (nftAddress, index) => {
          const tokenId = offeredNftIds[index].toString(); // Convert BigNumber to string
          let url;
          if (chain.name === 'Polygon') {
            url = `https://api-polygon.reservoir.tools/tokens/v7?collection=${nftAddress}`;
          } else if (chain.name === 'Goerli') {
            url = `https://api-goerli.reservoir.tools/tokens/v7?collection=${nftAddress}`;
          } else if (chain.name === 'Sepolia') {
            url = `https://api-sepolia.reservoir.tools/tokens/v7?collection=${nftAddress}`;
          } else if (chain.name === 'Arbitrum One') {
            url = `https://api-arbitrum.reservoir.tools/tokens/v7?collection=${nftAddress}`;
          } else if (chain.name === 'BNB Smart Chain') {
            url = `https://api-bsc.reservoir.tools/tokens/v7?collection=${nftAddress}`;
          } else if (chain.name === 'Optimism') {
            url = `https://api-optimism.reservoir.tools/tokens/v7?collection=${nftAddress}`;
          } else if (chain.name === 'Ethereum') {
            url = `https://api.reservoir.tools/tokens/v7?collection=${nftAddress}`;
          } else if (chain.name === 'Base') {
            url = `https://api-base.reservoir.tools/tokens/v7?collection=${nftAddress}`;
          }
          const response = await axios.get(url, {
            headers: {
              accept: '*/*',
              'x-api-key': '1f97bee7-5e00-5d41-b928-583f686a13d0', // Use the correct API key
            },
          });
          return {
            contractAddress: nftAddress,
            tokenId,
            imageUrl: response.data.tokens[0].token.collection.image, // Adjust based on actual response structure
            name: response.data.tokens[0].token.collection.name, // Adjust based on actual response structure
          };
        })
      );

      const requestNftDetails = await Promise.all(
        requestNfts.map(async (nftAddress) => {
          let url;
          if (chain.name === 'Polygon') {
            url = `https://api-polygon.reservoir.tools/tokens/v7?collection=${nftAddress}`;
          } else if (chain.name === 'Goerli') {
            url = `https://api-goerli.reservoir.tools/tokens/v7?collection=${nftAddress}`;
          } else if (chain.name === 'Sepolia') {
            url = `https://api-sepolia.reservoir.tools/tokens/v7?collection=${nftAddress}`;
          } else if (chain.name === 'Arbitrum One') {
            url = `https://api-arbitrum.reservoir.tools/tokens/v7?collection=${nftAddress}`;
          } else if (chain.name === 'BNB Smart Chain') {
            url = `https://api-bsc.reservoir.tools/tokens/v7?collection=${nftAddress}`;
          } else if (chain.name === 'Optimism') {
            url = `https://api-optimism.reservoir.tools/tokens/v7?collection=${nftAddress}`;
          } else if (chain.name === 'Ethereum') {
            url = `https://api.reservoir.tools/tokens/v7?collection=${nftAddress}`;
          } else if (chain.name === 'Base') {
            url = `https://api-base.reservoir.tools/tokens/v7?collection=${nftAddress}`;
          }
          const response = await axios.get(url, {
            headers: {
              accept: '*/*',
              'x-api-key': '1f97bee7-5e00-5d41-b928-583f686a13d0', // Use the correct API key
            },
          });
          return {
            contractAddress: nftAddress,
            imageUrl: response.data.tokens[0].token.collection.image, // Adjust based on actual response structure
            name: response.data.tokens[0].token.collection.name, // Adjust based on actual response structure
          };
        })
      );

      return {
        tradeHash,
        offeredNfts: offeredNftDetails,
        requestNfts: requestNftDetails,
        counterParty,
      };
    } catch (error) {
      console.error('Error fetching offer details:', error);
      return null;
    }
  };

  const fetchUserNFTs = async () => {
    if (!accountAddress) return;
    try {
      const response = await axios.get(api2, {
        headers: {
          accept: '*/*',
          'x-api-key': '1f97bee7-5e00-5d41-b928-583f686a13d0',
        },
      });
      const nftsData = response.data.tokens.reduce((acc, item) => {
        const token = item.token;
        const contractAddress = ethers.utils.getAddress(token.contract);
        if (!acc[contractAddress]) {
          acc[contractAddress] = [];
        }
        acc[contractAddress].push({
          tokenId: token.tokenId,
          name: token.collection.name,
          image: token.collection.image,
        });
        return acc;
      }, {});
      setUserNFTs(nftsData);
    } catch (error) {
      console.error('Failed to fetch user NFTs:', error);
    }
  };

  useEffect(() => {
    fetchUserNFTs();
  }, [accountAddress, api2]);

  const checkApprovalStatus = async (nftsData) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const approvalStatuses = {};

    for (const nft of nftsData) {
      const nftContract = new ethers.Contract(nft.contractAddress, erc721ABI, signer);
      const approvedAddress = await nftContract.getApproved(nft.tokenId);
      approvalStatuses[`${nft.contractAddress}-${nft.tokenId}`] =
        approvedAddress.toLowerCase() === contractA.toLowerCase();
    }

    setIsApproved(approvalStatuses);
  };

  const handleAcceptTrade = async (tradeHash) => {
    try {
      console.log('Contract address:', contractA);
      if (!contractA) {
        console.error('Contract address is not set');
        return;
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      console.log('Creating contract instance with:', { contractA, ABI });
      const contractInstance = new ethers.Contract(contractA, ABI, signer);

      const selectedNFTs = Object.values(selectedUserNFTs).flat();
      console.log('Selected NFTs:', selectedNFTs);

      // Approve NFTs if not already approved
      /*       for (const nft of selectedNFTs) {
        const key = `${nft.contractAddress}-${nft.tokenId}`;
        if (!isApproved[key]) {
          setApprovalLoading((prev) => ({ ...prev, [key]: true }));
          const nftContract = new ethers.Contract(nft.contractAddress, erc721ABI, signer);
          const approveTx = await nftContract.approve(contractA, nft.tokenId);
          await approveTx.wait();
          setIsApproved((prev) => ({ ...prev, [key]: true }));
          setApprovalLoading((prev) => ({ ...prev, [key]: false }));
        }
      } */

      const tokenIds = selectedNFTs.map((nft) => nft.tokenId);

      console.log('Accepting trade with:', { tradeHash, tokenIds, contractAddress: contractA });
      const tx = await contractInstance.acceptTrade(tradeHash, tokenIds);
      await tx.wait();

      console.log('Accept trade successful:', tx);
      setIsOpen(false);
      // Refresh the offers list
      const activeTrades = await fetchActiveTrades();
      const offersPromises = activeTrades.map(async (tradeHash) => fetchOfferDetails(tradeHash));
      const offers = await Promise.all(offersPromises);
      setActiveOffers(offers.filter((offer) => offer !== null));
    } catch (error) {
      console.error('Accept trade failed:', error);
      console.error('Error details:', {
        contractA,
        ABI,
        selectedUserNFTs,
        isApproved,
      });
    }
  };

  useEffect(() => {
    const loadOffers = async () => {
      if (contract) {
        const activeTrades = await fetchActiveTrades();
        const offersPromises = activeTrades.map(async (tradeHash) => fetchOfferDetails(tradeHash));
        const offers = await Promise.all(offersPromises);
        setActiveOffers(offers.filter((offer) => offer !== null));
      }
    };

    loadOffers();
  }, [contract]);

  const openModal = (offer) => {
    setSelectedOffer(offer);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedOffer(null);
  };

  const toast = useToast();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast({
          title: 'Address copied',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      },
      (err) => {
        console.error('Failed to copy: ', err);
        toast({
          title: 'Failed to copy address',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    );
  };

  const AddressDisplay = ({ address }) => (
    <Tooltip label={address}>
      <Text
        fontSize="xs"
        color="gray.500"
        cursor="pointer"
        onClick={(e) => {
          e.stopPropagation();
          copyToClipboard(address);
        }}
        _hover={{ textDecoration: 'underline' }}
      >
        {`${address.substr(0, 6)}...${address.substr(-4)}`}
      </Text>
    </Tooltip>
  );

  const handleIndividualApprove = async (nftAddress, tokenId) => {
    try {
      setApprovalLoading((prev) => ({ ...prev, [`${nftAddress}-${tokenId}`]: true }));
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const nftContract = new ethers.Contract(nftAddress, erc721ABI, signer);

      const tx = await nftContract.approve(contractA, tokenId);
      await tx.wait();

      console.log('Approval successful:', tx);
      setIsApproved((prev) => ({ ...prev, [`${nftAddress}-${tokenId}`]: true }));

      // Ensure selectedUserNFTs is updated correctly
      setSelectedUserNFTs((prev) => ({
        ...prev,
        [nftAddress]: [{ contractAddress: nftAddress, tokenId }],
      }));
    } catch (error) {
      console.error('Approval failed:', error);
    } finally {
      setApprovalLoading((prev) => ({ ...prev, [`${nftAddress}-${tokenId}`]: false }));
    }
  };

  useEffect(() => {
    console.log('selectedUserNFTs:', selectedUserNFTs);
    console.log('isApproved:', isApproved);
  }, [selectedUserNFTs, isApproved]);

  const isOfferAcceptable = () => {
    if (Object.keys(selectedUserNFTs).length !== selectedOffer.requestNfts.length) {
      console.log('Not all requested NFTs are selected');
      return false;
    }

    for (const [contractAddress, nfts] of Object.entries(selectedUserNFTs)) {
      for (const nft of nfts) {
        const key = `${contractAddress}-${nft.tokenId}`;
        if (!isApproved[key]) {
          console.log(`NFT not approved: ${key}`);
          return false;
        }
      }
    }

    console.log('Offer is acceptable');
    return true;
  };

  return (
    <VStack spacing={6} borderRadius="lg" boxShadow="lg" w="100%">
      <Heading>Feed</Heading>
      {accountAddress ? (
        <Box py={10} display="flex" flexDirection="column" alignItems="center" width="100%">
          {isLoading ? (
            <Flex justify="center" align="center" height="200px">
              <Spinner size="xl" color="blue.500" thickness="4px" />
            </Flex>
          ) : (
            <Grid
              templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
              gap={3}
              mt={3}
              px={[1, 3, 6]}
            >
              {activeOffers.map((offer) => (
                <Box
                  key={offer.tradeHash}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="xl"
                  borderWidth="3px"
                  borderColor="inherit"
                  p={3}
                  width={['100%', '220px', '240px']}
                  height={['auto', '280px', '300px']}
                  overflow="hidden"
                  onClick={() => openModal(offer)}
                  cursor="pointer"
                >
                  <Flex justifyContent="space-between" width="100%">
                    <Box>
                      <Text fontWeight="bold" mb={1}>
                        Offered:
                      </Text>
                      {offer.offeredNfts.map((nft) => (
                        <Box key={`${nft.contractAddress}`} display="flex" flexDirection="column" alignItems="center">
                          <Image
                            boxSize="60px"
                            borderRadius="md"
                            shadow="md"
                            src={nft.imageUrl}
                            alt={nft.name}
                            onError={(e) => console.error('Image load error:', e)}
                          />
                          <Text noOfLines={1} isTruncated>
                            {nft.name}
                          </Text>
                          <AddressDisplay address={nft.contractAddress} />
                        </Box>
                      ))}
                    </Box>
                    <Box>
                      <Text fontWeight="bold" mb={1}>
                        Requested:
                      </Text>
                      {offer.requestNfts.map((nft) => (
                        <Box key={nft.contractAddress} display="flex" flexDirection="column" alignItems="center">
                          <Image
                            boxSize="60px"
                            borderRadius="md"
                            shadow="md"
                            src={nft.imageUrl}
                            alt={nft.name}
                            onError={(e) => console.error('Image load error:', e)}
                          />
                          <Text noOfLines={1} isTruncated>
                            {nft.name}
                          </Text>
                          <AddressDisplay address={nft.contractAddress} />
                        </Box>
                      ))}
                    </Box>
                  </Flex>
                </Box>
              ))}
            </Grid>
          )}
        </Box>
      ) : (
        <Box py={10} textAlign="center">
          <Text fontSize="xl" fontWeight="bold" display="flex" alignItems="center" justifyContent="center">
            <ConnectButton
              chainStatus="icon"
              accountStatus="address"
              label="Sign in"
              showBalance={{
                smallScreen: false,
                largeScreen: false,
              }}
            />
            <Text ml={2}>to view the feed</Text>
          </Text>
          <Text mt={2} color="gray.500">
            Connect your wallet and refresh the page to see and interact with active offers
          </Text>
        </Box>
      )}
      <Modal isOpen={isOpen} onClose={closeModal} size="xl" isCentered>
        <ModalOverlay bg="blackAlpha.100" backdropFilter="blur(9px)" />
        <ModalContent bg="blackAlpha.900" borderRadius="xl">
          <ModalHeader>Offer Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {selectedOffer && (
              <VStack spacing={3} alignItems="center">
                <Divider />
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <Box>
                    <Text fontWeight="bold">Offer NFTs:</Text>
                    <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={3} mt={3}>
                      {selectedOffer.offeredNfts.map((nft) => (
                        <Box
                          key={`${nft.contractAddress}-${nft.tokenId}`}
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="xl"
                          borderWidth="3px"
                          borderColor="inherit"
                          p={3}
                          width="180px"
                          height="240px"
                          overflow="hidden"
                        >
                          <Image boxSize="120px" borderRadius="md" shadow="md" src={nft.imageUrl} alt={nft.name} />
                          <Text noOfLines={1} isTruncated mt={2}>
                            {nft.name}
                          </Text>
                          <AddressDisplay address={nft.contractAddress} />
                        </Box>
                      ))}
                    </Grid>
                  </Box>
                  <Box>
                    <Text fontWeight="bold">Request NFTs:</Text>
                    <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={3} mt={3}>
                      {selectedOffer.requestNfts.map((nft) => (
                        <Box
                          key={`${nft.contractAddress}`}
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="xl"
                          borderWidth="3px"
                          borderColor="inherit"
                          p={3}
                          width="180px"
                          height="240px"
                          overflow="hidden"
                        >
                          <Image boxSize="120px" borderRadius="md" shadow="md" src={nft.imageUrl} alt={nft.name} />
                          <Text noOfLines={1} isTruncated mt={2}>
                            {nft.name}
                          </Text>
                          <AddressDisplay address={nft.contractAddress} />
                        </Box>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
                {selectedOffer.requestNfts.every((nft) => {
                  const normalizedAddress = ethers.utils.getAddress(nft.contractAddress);
                  return userNFTs[normalizedAddress] && userNFTs[normalizedAddress].length > 0;
                }) ? (
                  <>
                    <Text fontWeight="bold" mt={4}>
                      Select your NFTs to trade:
                    </Text>
                    {selectedOffer.requestNfts.map((nft) => {
                      const normalizedAddress = ethers.utils.getAddress(nft.contractAddress);
                      return (
                        <Box key={normalizedAddress} width="100%">
                          <Text fontWeight="bold" mb={2}>
                            {nft.name}
                          </Text>
                          <Grid templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)']} gap={3}>
                            {userNFTs[normalizedAddress]?.map((userNFT) => (
                              <Box
                                key={userNFT.tokenId}
                                borderWidth="1px"
                                borderRadius="lg"
                                p={2}
                                cursor="pointer"
                                onClick={() => {
                                  setSelectedUserNFTs((prev) => ({
                                    ...prev,
                                    [normalizedAddress]: [userNFT],
                                  }));
                                }}
                                bg={
                                  selectedUserNFTs[normalizedAddress]?.[0]?.tokenId === userNFT.tokenId
                                    ? 'blue.500'
                                    : 'transparent'
                                }
                              >
                                <Image
                                  src={userNFT.image}
                                  alt={userNFT.name}
                                  boxSize="100px"
                                  objectFit="cover"
                                  borderRadius="md"
                                  mb={2}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = 'https://via.placeholder.com/100?text=No+Image';
                                  }}
                                />
                                <Text fontSize="sm" isTruncated>
                                  {userNFT.name} (ID: {userNFT.tokenId})
                                </Text>
                                {selectedUserNFTs[normalizedAddress]?.[0]?.tokenId === userNFT.tokenId && (
                                  <Button
                                    mt={2}
                                    size="sm"
                                    width="100%"
                                    colorScheme={
                                      isApproved[`${normalizedAddress}-${userNFT.tokenId}`] ? 'green' : 'blue'
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (!isApproved[`${normalizedAddress}-${userNFT.tokenId}`]) {
                                        handleIndividualApprove(normalizedAddress, userNFT.tokenId);
                                      }
                                    }}
                                    isLoading={approvalLoading[`${normalizedAddress}-${userNFT.tokenId}`]}
                                    loadingText="Approving"
                                  >
                                    {isApproved[`${normalizedAddress}-${userNFT.tokenId}`] ? <FaCheck /> : 'Approve'}
                                  </Button>
                                )}
                              </Box>
                            ))}
                          </Grid>
                        </Box>
                      );
                    })}
                    <Button
                      mt={4}
                      bgColor="green.600"
                      borderRadius={'xl'}
                      borderColor="black.500"
                      borderWidth="1px"
                      color="white"
                      size="md"
                      onClick={() => handleAcceptTrade(selectedOffer.tradeHash)}
                      isDisabled={!isOfferAcceptable()}
                    >
                      Accept Offer
                    </Button>
                  </>
                ) : (
                  <Button
                    bgColor="gray.600"
                    borderRadius={'xl'}
                    borderColor="black.500"
                    borderWidth="1px"
                    color="white"
                    size="md"
                    isDisabled={true}
                  >
                    You don't have the requested NFTs
                  </Button>
                )}
                <Divider />
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Feed;
