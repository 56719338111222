export const CONTRACT_GOERLI = '0x338EE507508372EB4283Ca03C7C4310Be9C59650';
export const CONTRACT_SEPOLIA = '0xa98728D5D1d718848116153141b9DcC883dBD7f4';
export const CONTRACT_POLYGON = '0x30cECF056F23f75e8D1de97a552775a390100263';
export const CONTRACT_ARBITRUM = '0x03269BAE56E35f130eA826C6d2AA94265d1e660F';
export const CONTRACT_LINEA = '0x28af67Eb534680971B5F9bB21586E84eA9b0C583';
export const CONTRACT_BINANCE = '0x28af67Eb534680971B5F9bB21586E84eA9b0C583';
export const CONTRACT_ETHEREUM = '0xF6Ba1F23D3290B767A87432e6bB2062BeeaD5Ef4';
export const CONTRACT_OPTIMISM = '0x82f9C4aE967e27E333F9EDC7Ef45224b62e9ea05';
export const CONTRACT_BASE = '0xB1BB87FF47988C3cA10822d13E0cE7A381C40974';

export const ABI = [
  { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
  { inputs: [], name: 'INVALID_FEE_SPLIT', type: 'error' },
  { inputs: [], name: 'LENGTHS_MISMATCH', type: 'error' },
  { inputs: [], name: 'NOT_COUNTER_PARTY', type: 'error' },
  { inputs: [], name: 'NOT_OWNER', type: 'error' },
  { inputs: [], name: 'ONLY_INITIATOR', type: 'error' },
  { inputs: [], name: 'PAY_FEE', type: 'error' },
  { inputs: [], name: 'ReentrancyGuardReentrantCall', type: 'error' },
  { inputs: [], name: 'TRADE_CLOSED', type: 'error' },
  { inputs: [], name: 'TRADE_EXISTS', type: 'error' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'bytes32', name: 'tradeHash', type: 'bytes32' }],
    name: 'TradeAccepted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'bytes32', name: 'tradeHash', type: 'bytes32' }],
    name: 'TradeCanceled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'tradeHash', type: 'bytes32' },
      { indexed: true, internalType: 'address[]', name: 'nfts', type: 'address[]' },
      { indexed: true, internalType: 'address[]', name: 'requestNfts', type: 'address[]' },
    ],
    name: 'TradeOpened',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'tradeHash', type: 'bytes32' },
      { internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
    ],
    name: 'acceptTrade',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'tradeHash', type: 'bytes32' }],
    name: 'cancelTrade',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'fee',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getActiveTrades',
    outputs: [{ internalType: 'bytes32[]', name: '', type: 'bytes32[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'tradeHash', type: 'bytes32' }],
    name: 'getOffer',
    outputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address[]', name: '', type: 'address[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'address[]', name: '', type: 'address[]' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'bytes', name: '', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'offerNfts', type: 'address[]' },
      { internalType: 'uint256[]', name: 'offerNftIds', type: 'uint256[]' },
      { internalType: 'address[]', name: 'requestNfts', type: 'address[]' },
      { internalType: 'address', name: 'counterParty', type: 'address' },
    ],
    name: 'openTrade',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pxTokenHolderFeePercentage',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pxTokenVault',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [{ internalType: 'uint256', name: '_fee', type: 'uint256' }],
    name: 'setFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_pxTokenHolderFeePercentage', type: 'uint256' }],
    name: 'setFeeSplit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_pxTokenVault', type: 'address' }],
    name: 'setPxTokenVault',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    name: 'trades',
    outputs: [
      { internalType: 'address', name: 'initiator', type: 'address' },
      { internalType: 'address', name: 'counterParty', type: 'address' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'withdrawFees', outputs: [], stateMutability: 'nonpayable', type: 'function' },
];
